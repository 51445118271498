<template>
  <el-form ref="form" :model="form" :rules="rules" class="form__login" @submit.native.prevent="onSubmit">
    <el-form-item label="E-Mail" prop="email">
      <el-input type="email" placeholder="E-Mail" v-model="form.email"></el-input>
    </el-form-item>

    <el-form-item label="Passwort" prop="password">
      <el-input type="password" placeholder="Passwort" v-model="form.password"></el-input>
    </el-form-item>

    <el-form-item class="m-t-2 m-b-0">
      <el-button type="primary" native-type="submit" class="w-100" :loading="loading">Anmelden</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import client from '@/config/directus.api';

export default {
  name: 'FormLogin',

  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      loading: false,
      rules: {
        email: [
          {
            required: true,
            message: 'Bitte füllen Sie alle erforderlichen Felder aus',
            trigger: 'blur'
          },
          {
            type: 'email',
            message: 'Bitte valide E-Mail-Adresse eingeben',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: 'Bitte füllen Sie alle erforderlichen Felder aus',
            trigger: 'blur'
          }
        ]
      }
    };
  },

  methods: {
    onSubmit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.signIn();
        } else {
          return false;
        }
      });
    },

    signIn() {
      this.loading = true;

      client.auth
        .login(this.form)
        .then(result => {
          const { data } = result;
          if (data) {
            const { access_token: accessToken } = data;
            if (accessToken) {
              // Store token
              this.$store.commit('auth/SET_AUTH', data);
            }
          }

          // Redirect to main page
          this.$router.push({ path: '/dashboard' });
        })
        .catch(err => {
          throw new Error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
