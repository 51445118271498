import client from '@/config/directus.api';
import { handleFieldRelations } from '@/services/utils';

const COLLECTION = 'timetracker';

class Timetracker {
  constructor(data) {
    const props = ['id', 'start', 'end', 'description', 'billed']; // Type in all non-relational field names
    const propsRelation = ['project', 'tag', 'user']; // Type in all relational field names
    const allProps = [...props, ...propsRelation];

    const fields = handleFieldRelations(data, allProps, propsRelation);

    Object.keys(fields).forEach(item => {
      this[item] = fields[item];
    });
  }
}

export function getWorkloadChartForProjects(filters = {}) {
  const filter = {
    start: {
      _gte: filters.start
    },
    end: {
      _lte: filters.end
    }
  };

  if (filters.project) {
    filter.project = {
      _eq: filters.project
    };
  }

  return client.transport
    .get('timetracker/chart/workload-projects', {
      params: {
        filter,
        limit: '-1',
        fields: ['start', 'end', 'project.*']
      }
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

export function getWorkloadChartPerDay(filters = {}) {
  const filter = {
    start: {
      _gte: filters.start
    },
    end: {
      _lte: filters.end
    }
  };

  if (filters.project) {
    filter.project = {
      _eq: filters.project
    };
  }

  const { mode } = filters;

  return client.transport
    .get('timetracker/chart/workload', {
      params: {
        filter,
        limit: '-1',
        fields: ['start', 'end', 'project.*'],
        mode
      }
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

export function getTimetracker(filter = {}) {
  return client.transport
    .get('timetracker', {
      params: {
        filter,
        limit: -1,
        fields: ['*', 'project.*', 'tag.*'],
        meta: ['total_count', 'filter_count']
      }
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

export function getTimetrackerById(id) {
  return client
    .items(COLLECTION)
    .readOne(id)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

export function addTimetracker(payload) {
  const data = new Timetracker(payload);

  return client
    .items(COLLECTION)
    .createOne(data)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

export function updateTimetracker(payload) {
  const { id } = payload;

  const tracker = new Timetracker(payload);

  return client
    .items(COLLECTION)
    .updateOne(id, tracker)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
