import * as User from '@/services/user';
import { get } from 'lodash';

export default {
  namespaced: true,

  state: {
    current: {
      id: undefined,
      email: '',
      first_name: '',
      last_name: '',
      status: ''
    }
  },

  getters: {
    currentUser(state) {
      return state.current;
    },

    isAdmin: state => {
      let result = false;
      const role = get(state.current, 'role');
      if (role) {
        const { name = '' } = role;
        if (name === 'Administrator') {
          result = true;
        }
      }

      return result;
    },

    isUser: state => {
      let result = false;
      const role = get(state.current, 'role');
      if (role) {
        const { name = '' } = role;
        if (name === 'User') {
          result = true;
        }
      }

      return result;
    }
  },

  mutations: {
    SET_CURRENT_USER(state, payload) {
      Object.assign(state.current, payload);
    }
  },

  actions: {
    GET_CURRENT_USER({ commit }) {
      return User.getMe()
        .then(response => {
          if (response) {
            commit('SET_CURRENT_USER', response);
          }

          return response;
        })
        .catch(err => {
          return err;
        });
    }
  }
};
