import * as Browser from '@/services/browser';

export default {
  namespaced: true,

  state: {
    visible: true,
    maxWidth: '17rem',
    width: '100vw'
  },

  mutations: {
    HIDE(state) {
      state.visible = false;
      state.maxWidth = '0';
    },

    SET_MAX_WIDTH(state, payload) {
      state.maxWidth = payload.maxWidth;
    },

    SET_WIDTH(state, payload) {
      state.width = payload.width;
    },

    SHOW() {
      state.visible = true;

      if (Browser.isDeviceXS() || Browser.isDeviceSM()) {
        state.maxWidth = '17rem';
      } else {
        state.maxWidth = '82px';
      }
    },

    TOGGLE(state) {
      state.visible = !state.visible;

      if (Browser.isDeviceXS() || Browser.isDeviceSM()) {
        if (state.visible) {
          state.maxWidth = '17rem';
        } else {
          state.maxWidth = '0';
        }
      } else {
        if (state.visible) {
          state.maxWidth = '17rem';
        } else {
          state.maxWidth = '82px';
        }
      }
    }
  }
};
