import * as SevdeskInvoiceService from '@/services/sevdesk.invoices';
import * as SevdeskVouchersService from '@/services/sevdesk.vouchers';
import { get, forEach, filter } from 'lodash';
import { endOfYear, getUnixTime, startOfYear } from 'date-fns';

export default {
  namespaced: true,

  state: {
    charts: {
      displayMode: 'month',
      height: 400,
      width: 400,
      income: {
        customers: { chartjs: { datasets: [{}] } },
        month: { chartjs: { datasets: [{}] } },
        year: { chartjs: { datasets: [{}] } }
      },
      outgoings: {
        category: { chartjs: { datasets: [{}] } },
        month: { chartjs: { datasets: [{}] } },
        year: { chartjs: { datasets: [{}] } }
      }
    },
    income: {
      all: {
        data: [],
        loaded: false
      },
      customers: {
        loaded: false
      },
      month: {
        loaded: false
      },
      year: {
        loaded: false
      },
      trend: {}
    },
    outgoings: {
      all: {
        data: [],
        loaded: false
      },
      category: {
        loaded: false
      },
      month: {
        loaded: false
      },
      year: {
        loaded: false
      },
      trend: {}
    },
    filter: {
      startDate: getUnixTime(startOfYear(new Date())),
      endDate: getUnixTime(endOfYear(new Date()))
    }
  },

  getters: {
    charts: state => state.charts,

    incomeChartsData(state) {
      let result = state.charts.income.month.chartjs;
      if (state.charts.displayMode !== 'month') {
        result = state.charts.income.year.chartjs;
      }
      return result;
    },

    incomeChartsDataLoaded(state) {
      let result = state.income.month.loaded;
      if (state.charts.displayMode !== 'month') {
        result = state.income.year.loaded;
      }
      return result;
    },

    incomeChartsCustomersLoaded(state) {
      return state.income.customers.loaded;
    },

    incomeChartsMonthLoaded(state) {
      return state.income.month.loaded;
    },

    incomeChartsYearLoaded(state) {
      return state.income.year.loaded;
    },

    incomeTrend(state) {
      return state.income.trend;
    },

    sumOpenInvoices(state) {
      let result = 0;

      forEach(state.income.all.data, invoice => {
        if (parseInt(invoice.status) === 200) {
          result += parseFloat(invoice.sumNet);
        }
      });

      return Number(result).toFixed(2);
    },

    sumOpenVouchers(state) {
      let result = 0;

      forEach(state.outgoings.all.data, voucher => {
        if (parseInt(voucher.status) !== 1000) {
          result += parseFloat(voucher.sumNet);
        }
      });

      return Number(result).toFixed(2);
    },

    openInvoices(state) {
      return filter(state.income.all.data, invoice => {
        return parseInt(invoice.status) === 200;
      });
    },

    openVouchers(state) {
      return filter(state.outgoings.all.data, voucher => {
        return parseInt(voucher.status) === 200;
      });
    },

    outgoingsChartsCategoryLoaded(state) {
      return state.outgoings.category.loaded;
    },

    outgoingsChartsMonthLoaded(state) {
      return state.outgoings.month.loaded;
    },

    outgoingsChartsYearLoaded(state) {
      return state.outgoings.year.loaded;
    },

    outgoingsTrend(state) {
      return state.outgoings.trend;
    }
  },

  mutations: {
    CLEAR_FILTERS(state) {
      state.filter.startDate = null;
      state.filter.endDate = null;
    },

    SET_FILTERS(state, payload) {
      const { startDate, endDate } = payload;
      state.filter.startDate = startDate;
      state.filter.endDate = endDate;
    },

    SET_INCOME_CHART_PER_MONTH(state, payload) {
      state.charts.income.month = payload;
    },

    SET_INCOME_CHART_PER_YEAR(state, payload) {
      state.charts.income.year = payload;
    },

    SET_INVOICES(state, payload) {
      state.income.all.data = payload;
    },

    SET_INVOICES_TREND(state, payload) {
      state.income.trend = payload.trend;
    },

    SET_VOUCHERS(state, payload) {
      state.outgoings.all.data = payload;
    },

    SET_VOUCHERS_TREND(state, payload) {
      state.outgoings.trend = payload.trend;
    }
  },

  actions: {
    async GET_ALL_INVOICES({ commit, state }) {
      const params = state.filter;

      try {
        state.income.all.loaded = false;
        const response = await SevdeskInvoiceService.getAllInvoices(params);
        const data = get(response, 'data.data.objects', []);
        state.income.all.loaded = true;
        commit('SET_INVOICES', data);
        return data;
      } catch (error) {
        throw new Error('Failure during request invoice data');
      }
    },

    async GET_ALL_VOUCHERS({ commit, state }) {
      const params = state.filter;

      try {
        state.outgoings.all.loaded = false;
        const response = await SevdeskVouchersService.getAllVouchers(params);
        const data = get(response, 'data.data.objects', []);
        state.outgoings.all.loaded = true;
        commit('SET_VOUCHERS', data);
        return data;
      } catch (error) {
        throw new Error('Failure during request invoice data');
      }
    },

    async GET_INCOME_CHART_PER_MONTH({ commit, state }) {
      const params = state.filter;

      try {
        state.income.month.loaded = false;
        const response = await SevdeskInvoiceService.getChartPerMonth(params);
        const data = get(response, 'data.data', []);
        commit('SET_INCOME_CHART_PER_MONTH', data);
        state.income.month.loaded = true;
        return data;
      } catch (error) {
        throw new Error('Failure during request chart data');
      }
    },

    async GET_INCOME_CHART_PER_YEAR({ commit, state }) {
      const params = state.filter;

      try {
        state.income.year.loaded = false;
        const response = await SevdeskInvoiceService.getChartPerYear(params);
        const data = get(response, 'data.data', []);
        commit('SET_INCOME_CHART_PER_YEAR', data);
        state.income.year.loaded = true;
        return data;
      } catch (error) {
        throw new Error('Failure during request chart data');
      }
    },

    async GET_INCOME_CHART_PER_CUSTOMERS({ commit, state }) {
      const params = state.filter;

      try {
        state.income.customers.loaded = false;
        const response = await SevdeskInvoiceService.getChartPerCustomers(params);
        const data = get(response, 'data.data', []);
        state.income.customers.loaded = true;
        return data;
      } catch (error) {
        throw new Error('Failure during request chart data');
      }
    },

    async GET_INCOME_TREND({ commit, state }) {
      const params = state.filter;

      try {
        const response = await SevdeskInvoiceService.getTrend(params);
        const data = get(response, 'data.data', []);
        commit('SET_INVOICES_TREND', data);

        return data;
      } catch (error) {
        throw new Error('Failure during request income trend');
      }
    },

    async GET_VOUCHER_CHART_PER_CATEGORY({ state }) {
      const params = state.filter;

      try {
        state.outgoings.category.loaded = false;
        const response = await SevdeskVouchersService.getChartPerCategory(params);
        const data = get(response, 'data.data', []);
        state.outgoings.category.loaded = true;
        return data;
      } catch (error) {
        throw new Error('Failure during request voucher category chart data');
      }
    },

    async GET_VOUCHER_CHART_PER_MONTH({ state }) {
      const params = state.filter;

      try {
        state.outgoings.month.loaded = false;
        state.loadedOutgoingsMonthCharts = false;
        const response = await SevdeskVouchersService.getChartPerMonth(params);
        const data = get(response, 'data.data', []);
        state.outgoings.month.loaded = true;
        state.loadedOutgoingsMonthCharts = true;
        return data;
      } catch (error) {
        throw new Error('Failure during request voucher month chart data');
      }
    },

    async GET_VOUCHER_CHART_PER_YEAR({ state }) {
      const params = state.filter;

      try {
        state.outgoings.year.loaded = false;
        const response = await SevdeskVouchersService.getChartPerYear(params);
        const data = get(response, 'data.data', []);
        state.outgoings.year.loaded = true;
        return data;
      } catch (error) {
        throw new Error('Failure during request voucher year chart data');
      }
    },

    async GET_VOUCHER_TREND({ commit, state }) {
      const params = state.filter;

      try {
        const response = await SevdeskVouchersService.getTrend(params);
        const data = get(response, 'data.data', []);
        commit('SET_VOUCHERS_TREND', data);

        return data;
      } catch (error) {
        throw new Error('Failure during request income trend');
      }
    }
  }
};
