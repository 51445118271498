import client from '@/config/directus.api';
const COLLECTION = 'travel';

class TravelItem {
  constructor(obj) {
    this.date = obj.date;
    this.project = obj.project;
    this.company = obj.company;
    this.description = obj.description;
    this.user = obj.user;
  }
}

export function getTravelings(filter = {}) {
  return client
    .items(COLLECTION)
    .readByQuery({
      filter,
      fields: ['*.*.*'],
      limit: '-1'
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

export function getTravelById(id) {
  return client
    .items(COLLECTION)
    .readOne(id)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

export function addTravel(payload) {
  const data = new TravelItem(payload);

  return client
    .items(COLLECTION)
    .createOne(data)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
