<template>
  <el-menu mode="horizontal" :router="true" :collapse-transition="false" class="a-center">
    <el-menu-item
      v-for="item in items"
      :index="item.name"
      :key="item.id"
      :route="item.link"
      :class="{ 'is-active--custom': $route.path.includes(item.name) }"
      @click="item.clickHandler"
    >
      <span slot="title" v-html="item.title" v-if="!item.children.length"></span>
      <i class="material-icons m-r-1" v-if="!item.children.length">{{ item.icon.name }}</i>

      <el-submenu :index="item.link.path" v-if="item.children.length">
        <template slot="title">
          <i class="material-icons m-r-1">{{ item.icon.name }}</i>
          {{ item.title }}
          <span class="m-l-1"></span>
        </template>
        <el-menu-item :index="child.link.path" v-for="child in item.children" :key="child.id">
          <i class="material-icons m-r-1">{{ child.icon.name }}</i>
          {{ child.title }}
        </el-menu-item>
      </el-submenu>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: 'AppNavigationMain',

  data() {
    return {
      items: [
        {
          id: 1,
          title: 'Dashboard',
          name: 'dashboard',
          link: {
            path: '/dashboard'
          },
          icon: {
            name: 'dashboard'
          },
          clickHandler: () => {},
          children: []
        },
        {
          id: 2,
          title: 'Zeiten',
          name: 'zeiten',
          link: {
            path: '/times'
          },
          icon: {
            name: 'alarm'
          },
          clickHandler: () => {},
          children: []
        },
        {
          id: 3,
          title: 'Umsatz',
          name: 'sales',
          link: {
            path: '/sales'
          },
          icon: {
            name: 'attach_money'
          },
          clickHandler: () => {},
          children: []
        },
        {
          id: 4,
          title: 'Report',
          name: 'report',
          link: {
            path: '/report'
          },
          icon: {
            name: 'equalizer'
          },
          clickHandler: () => {},
          children: [
            {
              id: 41,
              title: 'Zeiten',
              name: 'zeiten',
              link: {
                path: '/report/time'
              },
              icon: {
                name: 'alarm'
              }
            },
            {
              id: 42,
              title: 'Reisen',
              name: 'reisen',
              link: {
                path: '/report/travel'
              },
              icon: {
                name: 'directions_car'
              }
            }
          ]
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.el-menu-item {
  padding-bottom: 0;
}

.el-menu--horizontal {
  .el-menu--popup {
    .el-menu-item {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;

      &.is-active {
        background-color: transparentize($c-brand, 0.9);
        font-weight: 700;
      }
    }
  }
}
</style>
