import * as TravelService from '@/services/travel';

export default {
  namespaced: true,

  state: {
    all: [],
    filter: {
      company: null,
      project: null,
      start: null,
      end: null
    },
    form: {
      project: null,
      company: null,
      date: null,
      description: null
    }
  },

  getters: {},

  mutations: {
    CLEAR_FILTERS(state) {
      state.filter.company = null;
      state.filter.project = null;
      state.filter.tag = null;
      state.filter.start = null;
      state.filter.end = null;
      state.filter.billed = null;
    },

    SET_FILTERS(state, payload) {
      state.filter.company = payload.company;
      state.filter.project = payload.project;
      state.filter.start = payload.start;
      state.filter.end = payload.end;
    },

    SET_FORMULAR(state, payload) {
      state.form.project = payload.project;
      state.form.company = payload.company;
      state.form.date = payload.date;
      state.form.description = payload.description;
    },

    SET_TRAVELINGS(state, payload) {
      const index = _.findIndex(state.all, item => {
        return item.id === payload.id;
      });

      if (index !== -1) {
        state.all[index] = payload;
      } else {
        state.all.push(payload);
      }
    }
  },

  actions: {
    ADD_TRAVELING({ commit }, payload) {
      return TravelService.addTravel(payload)
        .then(response => {
          const { data } = response;
          if (data) {
            commit('SET_TRAVELINGS', data);
          }
          return response;
        })
        .catch(err => {
          return err;
        });
    },

    GET_TRAVELING_RESULTS({ state }) {
      const filter = {
        date: {
          _between: `${state.filter.start},${state.filter.end}`
        }
      };

      if (state.filter.project) {
        filter.project = {
          _eq: state.filter.project
        };
      }

      if (state.filter.company) {
        filter.company = {
          _eq: state.filter.company
        };
      }

      return TravelService.getTravelings(filter)
        .then(response => {
          const { data } = response;
          if (data) {
            // commit('SET_TRAVELINGS', response.data);
            state.all = data.slice();
          }

          return response;
        })
        .catch(err => {
          return err;
        });
    }
  }
};
