export default {
  namespaced: true,

  state: {
    component: null,
    headline: '',
    visible: false
  },

  mutations: {
    CLOSE(state) {
      state.visible = false;
      state.headline = '';
    },

    SET(state, payload) {
      state.component = payload.component;
      state.headline = payload.headline;
    },

    SHOW(state) {
      state.visible = true;
    },

    TOGGLE(state) {
      state.visible = !state.visible;
    }
  }
};
