<!--
	@component:	DIALOG
	@desc:		This component handle multiple dialogs.
	@author: 	Yannick Herzog, info@hit-services.net
	@created:	2019-01-21
	@modified: 	2019-02-05
 -->
<template>
  <div class="app-dialog__container">
    <!-- Loop over all dialog instances to show multiple dialogs -->
    <el-dialog
      v-for="dialog in dialogInstances"
      :key="dialog.id"
      :visible.sync="dialog.visible"
      :fullscreen="dialog.fullscreen"
      :title="dialog.title"
      :width="dialog.width"
      :top="dialog.top"
      :append-to-body="true"
      :modal-append-to-body="false"
      @closed="onDialogClose(dialog)"
    >
      <!-- Make sure to register component first -->
      <component :is="dialog.loadComponent" :data="dialog.data" v-if="dialog.loadComponent"></component>

      <span
        v-if="dialog.content"
        v-html="dialog.content"
        v-loading="dialog.loading"
        :class="{ 'text-center d-block': dialog.textCenter }"
      ></span>
      <span v-else v-loading="dialog.loading"></span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Dialog',

  computed: {
    dialogInstances() {
      return this.$store.state.dialog.instances;
    }
  },

  methods: {
    onDialogClose(dialog) {
      this.$store.commit('dialog/CLOSE', { instance: dialog });
    }
  }
};
</script>
