import * as TagService from '@/services/tag';

export default {
  namespaced: true,

  state: {
    all: []
  },

  mutations: {
    SET_TAGS(state, payload) {
      state.all = payload;
    }
  },

  actions: {
    GET_TAGS({ commit }) {
      TagService.getTags()
        .then(response => {
          const { data } = response;
          if (data) {
            commit('SET_TAGS', data);
          }

          return response;
        })
        .catch(err => {
          return err;
        });
    }
  }
};
