/* eslint-disable */
// import i18n from './../i18n/index';

export default {
    HIDE_LOADING: function(state, payload) {
        state.app.loading = false;
    },

    SET_APP_LANGUAGE: function(state, payload) {
        // i18n.locale = payload.language;
        state.app.language = payload.language;
    },

    SET_TOGGLE_LOADING: function(state, payload) {
        state.app.loading = !state.app.loading;
    },

    SET_TRANSLATION: function(state, payload) {
        // i18n.locale = payload.locale;
        // i18n.setLocaleMessage(payload.locale, payload.message);
        document.querySelector('html').setAttribute('lang', payload.locale);

        const obj = {
            id: payload.locale,
            messages: payload.message
        };

        const index = _.find(state.translations, { id: payload.locale });

        if (index !== -1) {
            state.translations.splice(index, 1, obj);
        } else {
            state.translations.push(obj);
        }
    }
};
