import API from '@/config/sevdesk.api';
const TOKEN = process.env.VUE_APP_SEVDESK_API_TOKEN;

export async function getAllVouchers(params = {}) {
  params.token = TOKEN;

  const response = await API.get('/vouchers', {
    params
  });

  return response;
}

export async function getChartPerMonth(params = {}) {
  params.token = TOKEN;

  const response = await API.get('/vouchers/chart/month', {
    params
  });

  return response;
}

export async function getChartPerYear(params = {}) {
  params.token = TOKEN;

  const response = await API.get('/vouchers/chart/year', {
    params
  });

  return response;
}

export async function getChartPerCategory(params = {}) {
  params.token = TOKEN;

  const response = await API.get('/vouchers/chart/category', {
    params
  });

  return response;
}

export async function getTrend(params = {}) {
  params.token = TOKEN;

  const response = await API.get('/vouchers/trend', {
    params
  });

  return response;
}
