import AppLayout from '@/layouts/AppLayout.vue';

export default {
  path: '/times',
  component: AppLayout,
  children: [
    {
      path: '',
      name: 'times',
      component: () => import(/* webpackChunkName: "times" */ '@/views/Times/TimesIndex.vue'),
      meta: { auth: true }
    }
  ],
  meta: { auth: true }
};
