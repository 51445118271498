import client from '@/config/directus.api';
const COLLECTION = 'projects';

export function getProjects() {
  return client
    .items(COLLECTION)
    .readByQuery({
      sort: 'name',
      filter: {
        status: 'published'
      }
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
