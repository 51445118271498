import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from './state';

// Modules
import aside from '@/store/modules/aside';
import auth from '@/store/modules/auth';
import company from '@/store/modules/company';
import dialog from '@/store/modules/dialog';
import offcanvas from '@/store/modules/offcanvas';
import project from '@/store/modules/project';
import report from '@/store/modules/report';
import sevdesk from '@/store/modules/sevdesk';
import tag from '@/store/modules/tag';
import targetFigures from '@/store/modules/target-figures';
import tracking from '@/store/modules/tracking';
import travel from '@/store/modules/travel';
import user from '@/store/modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state,

  mutations,

  actions,

  getters,

  modules: {
    aside,
    auth,
    company,
    dialog,
    offcanvas,
    project,
    report,
    sevdesk,
    tag,
    targetFigures,
    tracking,
    travel,
    user
  }
});
