import * as Language from '@/services/language';

export default {
  GET_TRANSLATION({}, payload) {
    return Language.getTranslation(payload)
      .then(response => {
        return response;
      })
      .catch(err => {
        return err;
      });
  }
};
