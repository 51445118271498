<template>
  <el-dropdown trigger="click">
    <span class="el-dropdown-link flex a-center">
      <i class="material-icons m-r-half">account_circle</i>
      <span class="el-dropdown-link__name" v-if="user.first_name && user.last_name">{{ user.first_name }} {{ user.last_name }}</span>
      <i class="material-icons m-l-half--md">arrow_drop_down</i>
    </span>

    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item>
        <el-button @click="onClickLogout" type="text" class="w-100">
          <div class="valign-center">
            <i class="material-icons m-r-small">exit_to_app</i>
            <span>Abmelden</span>
          </div>
        </el-button>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DropdownUserProfile',

  computed: {
    ...mapGetters({
      user: 'user/currentUser'
    })
  },

  methods: {
    fadeOutPage() {
      const body = document.querySelector('body');
      body.style.transition = 'all 0.3s ease-in-out';
      body.style.opacity = '0';
    },

    onClickLogout() {
      this.fadeOutPage();

      setTimeout(() => {
        this.$router.push({ path: '/logout' });
      }, 300);
    }
  }
};
</script>

<style scoped lang="scss">
.el-dropdown {
  cursor: pointer;

  &-link__name {
    display: none;

    @media screen and (min-width: $screen-md) {
      display: inline;
    }
  }
}
</style>
