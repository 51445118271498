import client from '@/config/directus.api';

const COLLECTION = 'companies';

/**
 * Get company by id
 * @param {int} id
 * @return {promise}
 */
export function getCompanyById(id) {
  return client
    .items(COLLECTION)
    .readByQuery({
      filter: {
        id
      }
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

/**
 * Get companies
 */
export function getCompanies() {
  return client
    .items(COLLECTION)
    .readByQuery({
      sort: 'name',
      filter: {
        status: 'published'
      }
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

/**
 * Update company by id
 * @param {int} id
 * @param {object} payload
 * @return {promise}
 */
export function updateCompanyById(id, payload) {
  return client
    .items(COLLECTION)
    .updateOne(id, payload)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
