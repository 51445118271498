/* eslint-disable no-unused-vars */
import * as TargetFiguresService from '@/services/target-figures';
import { findIndex, get } from 'lodash';

export default {
  namespaced: true,

  state: {
    all: []
  },

  getters: {
    all(state) {
      return state.all;
    },

    currentMonthWorkloadTargetFigures(state, getters) {
      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();
      const workload = getters.workload.find(item => item.month === month && item.year === year);

      return get(workload, 'value');
    },

    currentYearWorkloadTargetFigures(state, getters) {
      let result = 0;
      const year = new Date().getFullYear();
      const workload = getters.workload.filter(item => item.year === year);
      workload.forEach(item => {
        const value = get(item, 'value');
        if (value) {
          result += parseInt(value);
        }
      });

      return result;
    },

    workload(state) {
      return state.all.filter(item => item.type.name === 'Arbeitsstunden');
    }
  },

  mutations: {
    SET_TARGET_FIGURES(state, payload) {
      const index = findIndex(state.all, item => {
        return item.id === payload.id;
      });

      if (index !== -1) {
        state.all[index] = payload;
      } else {
        state.all.push(payload);
      }
    }
  },

  actions: {
    GET_TARGET_FIGURES({ state }, payload) {
      const filter = get(payload, 'filter', {});

      return TargetFiguresService.getTargetFigures(filter)
        .then(response => {
          const { data } = response;

          if (data) {
            state.all = data;
          }
          return response;
        })
        .catch(err => {
          return err;
        });
    }
  }
};
