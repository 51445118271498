import client from '@/config/directus.api';

export function getMe() {
  // const response = await client.users.me.read();
  // const { data } = response;
  // console.log('me', data, response);
  // if (data) {
  //   const { id } = data;

  //   if (id) {
  //     return client
  //       .items('directus_users')
  //       .read(id)
  //       .then(result => {
  //         return result;
  //       })
  //       .catch(err => {
  //         return err;
  //       });
  //   }
  // }

  return client.users.me
    .read({
      fields: ['*', 'role.*']
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
