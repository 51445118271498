import * as SevdeskAccountingTypeService from '@/services/sevdesk.accountingTypes';
import * as SevdeskContactService from '@/services/sevdesk.contacts';
import { get } from 'lodash';

export default {
  namespaced: true,

  state: {
    accountingTypes: [],
    contacts: []
  },

  getters: {},

  mutations: {
    SET_ACCOUNTING_TYPES(state, payload) {
      state.accountingTypes = payload;
    },

    SET_CONTACTS(state, payload) {
      state.contacts = payload;
    }
  },

  actions: {
    async GET_ACCOUNTING_TYPES({ commit }) {
      try {
        const response = await SevdeskAccountingTypeService.getAll();
        const objects = get(response, 'data.objects', []);
        commit('SET_ACCOUNTING_TYPES', objects);
      } catch (error) {
        throw new Error('Failure during request accounting types');
      }
    },

    async GET_CONTACTS({ commit }) {
      try {
        const response = await SevdeskContactService.getAll();
        const objects = get(response, 'data.objects', []);
        commit('SET_CONTACTS', objects);
      } catch (error) {
        throw new Error('Failure during request contacts');
      }
    }
  }
};
