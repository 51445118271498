<template>
  <div class="view">
    <div class="view__inner">
      <!-- Logo -->
      <div class="text-center">
        <SVGLogo class="view__logo" />
      </div>

      <el-alert
        :title="'Erfolg'"
        :closable="false"
        type="success"
        :description="'Du wurdest erfolgreich abgemeldet'"
        show-icon
        class="m-t-2"
      ></el-alert>

      <el-button type="primary" plain class="m-t-2 w-100" @click="$router.push({ path: '/login' })">Nochmals anmelden</el-button>
    </div>
  </div>
</template>

<script>
import { clearUserData } from '@/services/auth';
import SVGLogo from '@/assets/img/logo.svg';

export default {
  name: 'Logout',

  components: {
    SVGLogo
  },

  mounted() {
    if (this.$route.query.success !== '1') {
      clearUserData();

      this.$store.dispatch('auth/LOGOUT').then(() => {
        this.$router.push({ path: '/logout', query: { success: 1 } });
        window.location.reload();
      });
    }

    this.registerEvents();
  },

  methods: {
    fadeInPage() {
      const body = document.querySelector('body');
      body.style.transition = 'all 0.3s ease-in-out';
      body.style.opacity = '1';
    },

    fadeOutPage() {
      const body = document.querySelector('body');
      body.style.transition = 'all 0.3s ease-in-out';
      body.style.opacity = '0';
    },

    registerEvents() {
      document.addEventListener('DOMContentLoaded', () => {
        setTimeout(() => {
          this.fadeInPage();
        }, 0);
      });
    },

    unregisterEvents() {
      document.removeEventListener('DOMContentLoaded', () => {
        this.fadeInPage();
      });
    }
  },

  destroyed() {
    this.unregisterEvents();
  }
};
</script>

<style lang="scss" scoped>
.view {
  &__logo {
    text {
      fill: $c-black;
    }
  }

  &__inner {
    background-color: #ffffff;
    border-radius: $border-radius;
    box-shadow: 0 0 15px 0 transparentize($c-gray, 0.7);
    padding: 2rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 30rem;
    min-width: 30rem;
    width: 100%;
  }
}
</style>
