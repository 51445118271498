import * as ProjectService from '@/services/project';
import { sortBy } from 'lodash';

export default {
  namespaced: true,

  state: {
    all: []
  },

  getters: {
    sortedProjects(state) {
      return sortBy(state.all, item => item.name);
    }
  },

  mutations: {
    SET_PROJECTS(state, payload) {
      state.all = payload;
    }
  },

  actions: {
    GET_PROJECTS({ commit }) {
      ProjectService.getProjects()
        .then(response => {
          const { data } = response;
          if (data) {
            commit('SET_PROJECTS', data);
          }

          return response;
        })
        .catch(err => {
          return err;
        });
    }
  }
};
