/* eslint-disable no-undef */
/* eslint-disable space-before-function-paren */
import Vue from 'vue';
import router from '@/router/index';
import { clearUserData } from '@/services/auth';
import { get } from 'lodash';
import { Directus, LocalStorage } from '@directus/sdk';

const url = process.env.VUE_APP_API;
const client = new Directus(url, {
  auth: {
    mode: 'cookie',
    autoRefresh: true
  },
  storage: {
    mode: LocalStorage
  }
});

/*
 * Interceptor to handle unauthorized requests
 */
client.transport.axios.interceptors.response.use(
  function(response) {
    // All fine
    return response;
  },
  function(error) {
    // console.log('ERRROR', { error });
    const { status = 401 } = error.response;
    const errors = get(error, 'response.data.errors', []);

    /**
     * Display all errors from backend.
     * These are mostly validation errors.s
     */
    if (errors.length) {
      errors.forEach(errorItem => {
        let title = 'Fehler';
        const { message = '', extensions } = errorItem;

        if (extensions) {
          let { code } = extensions;
          if (code) {
            title = code;
          }
        }

        Vue.prototype.$notify({
          type: 'error',
          title,
          message: message,
          duration: 30000,
          showClose: true
        });
      });
    }

    /**
     * Redirect to login if authentication is required
     * ToDo: Exclude login requests
     */
    if (status === 401 || status === 403) {
      // Clear local storage
      clearUserData();

      // Redirect to login
      const routePath = get(router, 'app._route.path', '');
      const loginPath = '/login';
      if (routePath !== loginPath) {
        router.push({ path: loginPath });
      }
    }

    return Promise.reject(error);
  }
);

export default client;
