import API from '@/config/sevdesk.api';
const TOKEN = process.env.VUE_APP_SEVDESK_API_TOKEN;

export async function getAllInvoices(params = {}) {
  params.token = TOKEN;

  const response = await API.get('/invoices', {
    params
  });

  return response;
}

export async function getChartPerMonth(params = {}) {
  params.token = TOKEN;

  const response = await API.get('/invoices/chart/month', {
    params
  });

  return response;
}

export async function getChartPerYear(params = {}) {
  params.token = TOKEN;

  const response = await API.get('/invoices/chart/year', {
    params
  });

  return response;
}

export async function getChartPerCustomers(params = {}) {
  params.token = TOKEN;

  const response = await API.get('/invoices/chart/customers', {
    params
  });

  return response;
}

export async function getTrend(params = {}) {
  params.token = TOKEN;

  const response = await API.get('/invoices/trend', {
    params
  });

  return response;
}
