<template>
  <div class="el-header-inner">
    <!-- Left -->
    <div class="el-header-inner--left">
      <!-- Logo -->
      <SVGLogo class="logo" />
      <span class="version font--small">{{ version }}</span>
    </div>

    <!-- Center -->
    <div class="el-header-inner--center">
      <AppNavigationMain />
    </div>

    <!-- Right -->
    <div class="el-header-inner--right">
      <el-button type="primary" class="m-r-3 valign-center" size="small" @click.prevent="onClickBtnWorktime">
        <i class="material-icons m-r-small">add_circle</i>
        <span>Hinzufügen</span>
      </el-button>
      <!-- <DropdownCreateTracking class="m-r-3"/> -->
      <DropdownUserProfile />
    </div>
  </div>
</template>

<script>
import AppNavigationMain from '@/components/App/AppNavigationMain';
// import DropdownCreateTracking from '@/components/Button/DropdownCreateTracking.vue';
import DropdownUserProfile from '@/components/Button/DropdownUserProfile.vue';
import SVGLogo from '@/assets/img/logo.svg';

export default {
  name: 'AppHeader',

  components: {
    AppNavigationMain,
    // DropdownCreateTracking,
    DropdownUserProfile,
    SVGLogo
  },

  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    }
  },

  methods: {
    onClickBtnWorktime() {
      this.$store.commit('offcanvas/SET', {
        component: 'Tab/TabFormTimetrackingTravelTracking.vue',
        headline: 'Arbeitszeit- und Reisekosten erfassen'
      });

      this.$store.commit('offcanvas/TOGGLE');
    }
  }
};
</script>

<style scoped lang="scss">
.el-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  @media screen and (min-width: $screen-md) {
    justify-content: flex-start;
  }

  &--center {
    display: none;
    @media screen and (min-width: $screen-md) {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &--left {
    @media screen and (min-width: $screen-md) {
      display: flex;
      align-items: center;
    }

    .version {
      color: $c-gray;
      display: none;
      @media screen and (min-width: $screen-md) {
        display: inline-block;
        margin-left: 0.5rem;
      }
    }
  }

  &--right {
    display: none;

    @media screen and (min-width: $screen-md) {
      display: flex;
      align-items: center;
      flex: 1 0 auto;
      justify-content: flex-end;
    }
  }
}

.logo {
  // margin: 0.6rem 0;
}
</style>
