import AppLayout from '@/layouts/AppLayout.vue';

export default {
  path: '/dashboard',
  component: AppLayout,
  children: [
    {
      path: '',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/DashboardIndex.vue'),
      meta: { auth: true }
    }
  ],
  meta: { auth: true }
};
