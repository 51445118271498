import * as Company from '@/services/company';

export default {
  namespaced: true,

  state: {
    all: [],
    current: {}
  },

  mutations: {
    SET(state, payload) {
      state.current = payload;
    },

    SET_COMPANIES(state, payload) {
      state.all = payload;
    }
  },

  actions: {
    GET_COMPANY_BY_ID({ commit }, payload) {
      return Company.getCompanyById(payload.id)
        .then(response => {
          commit('SET', response.data);

          return response;
        })
        .catch(err => {
          return err;
        });
    },

    GET_COMPANIES({ commit }) {
      return Company.getCompanies()
        .then(response => {
          const { data } = response;
          if (data) {
            commit('SET_COMPANIES', data);
          }

          return response;
        })
        .catch(err => {
          return err;
        });
    },

    UPDATE_COMPANY_BY_ID({ commit }, payload) {
      return Company.updateCompanyById(payload.id, payload)
        .then(response => {
          const { data } = response;
          if (data) {
            commit('SET', response.data);
          }

          return response;
        })
        .catch(err => {
          return err;
        });
    }
  }
};
