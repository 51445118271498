/* eslint-disable no-prototype-builtins */
import { forEach, isObject, isInteger, isString } from 'lodash';

/**
 * Search for a given string in an array with objects. Used to find a string
 * Example: Used to find a string in an table with accountants or clients
 *
 * @param {String} searchString
 * @param {array} searchInArray
 * @param {array} searchInColumns
 * @returns array
 */
export function searchStringInArray(searchString, searchInArray, searchInColumns) {
  const filteredData = searchInArray.filter(row => {
    let match = false;

    forEach(searchInColumns, column => {
      if (!row[column]) {
        return;
      }

      let stringIncluded = false;
      const currentValue = row[column].toString();
      const lowercaseValue = currentValue.toLowerCase();
      stringIncluded = lowercaseValue.includes(searchString);

      if (stringIncluded) {
        match = true;
      }
    });

    return match ? row : false;
  });

  return filteredData;
}

/**
 * Get object size
 *
 * @param {Object} obj
 * @returns {int}
 */
export function getObjectSize(obj) {
  let size = 0;

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      size++;
    }
  }

  return size;
}

/**
 * Source: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 */
export function guid() {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

/**
 * Get chart data
 * @param {Object} obj
 * @param {Object} colors
 */
export function getChartDataSet(obj, colors) {
  return {
    label: obj.displayName,
    borderColor: colors.borderColor,
    backgroundColor: colors.backgroundColor,
    fill: 'start',
    data: obj.values
  };
}

/**
 * Return base64 string of file
 * @Source      https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
 *
 * @param {File} file
 * @return {String}
 */
export function getBase64(file) {
  let reader = new FileReader();

  return new Promise(resolve => {
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );

    reader.readAsDataURL(file);
  });
}

/**
 * Handle field data and relations for relational fields and non-relational fields
 * @param {Object} data
 * @param {Array} allProps
 * @param {Array} propsRelation
 * @returns {Objects}
 */
export function handleFieldRelations(data, allProps, propsRelation) {
  const result = {};

  Object.keys(data).forEach(item => {
    if (!allProps.includes(item)) {
      return;
    }

    /**
     * Check for m2o/m2m relation
     */
    if (propsRelation.includes(item)) {
      if (isObject(data[item])) {
        const { id } = data[item];
        if (id) {
          result[item] = id;
        }
      } else if (isInteger(data[item]) || isString(data[item])) {
        result[item] = data[item];
      }
    } else {
      /**
       * Update non-relational field
       */
      result[item] = data[item];
    }
  });

  return result;
}
