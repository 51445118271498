/* eslint-disable space-before-function-paren */
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import client from '@/config/directus.api';
import { clearUserData } from '@/services/auth';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: routes,
  linkActiveClass: 'is-active'
});

/**
 * Process for each route to check for authentification and redirection
 * @param  {Object} to
 * @param  {Object} from
 * @param  {Function} next
 * @return {void}
 */
router.beforeEach(async (to, from, next) => {
  const IS_AUTHENTICATED = localStorage.getItem('auth_token');

  // ToDO: Redirect user to login page if not authenticated
  if (to.meta.auth) {
    try {
      await client.auth.refresh();
    } catch (error) {
      clearUserData();
      next({ path: '/login' });
      return false;
    }

    if (!IS_AUTHENTICATED) {
      clearUserData();
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
