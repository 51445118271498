import AppLayout from '@/layouts/AppLayout.vue';

export default {
  path: '/report',
  component: AppLayout,
  children: [
    {
      path: '',
      redirect: 'time'
    },
    {
      path: 'time',
      alias: '',
      name: 'report-time',
      component: () => import(/* webpackChunkName: "report" */ '@/views/Report/Tracking.vue'),
      meta: { auth: true }
    },
    {
      path: 'travel',
      name: 'report-travel',
      component: () => import(/* webpackChunkName: "report" */ '@/views/Report/Travel.vue'),
      meta: { auth: true }
    }
  ],
  meta: { auth: true }
};
