/* eslint-disable no-prototype-builtins */
/* eslint-disable space-before-function-paren */
import Vue from 'vue';
import axios from 'axios';
// import i18n from "@/i18n";

const SEVDESK_API = axios.create({
  baseURL: process.env.VUE_APP_SEVDESK_API,
  headers: {
    'Content-Type': 'application/json'
  }
});

/*
 * Interceptor to handle request
 */
SEVDESK_API.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

/*
 * Interceptor to handle unauthorized requests
 */
SEVDESK_API.interceptors.response.use(
  function(response) {
    // All fine
    // Vue.prototype.$Progress.finish();
    return response;
  },
  function(error) {
    // Vue.prototype.$Progress.fail();

    // let responseError = error.response.data;

    // Show message for all kinds of errors
    // Vue.prototype.$notify({
    // 	type: 'error',
    // 	title: responseError.statusCode || error.response.status,
    // 	message: responseError.errorMessage || error.response.statusText,
    // 	duration: 8000,
    // 	showClose: true
    // });

    // Display all errors from backend.
    if (error.response.data.hasOwnProperty('error')) {
      Vue.prototype.$notify({
        type: 'error',
        title: 'Error',
        message: error.response.data.error.message,
        duration: 0,
        showClose: true
      });
    }

    return Promise.reject(error);
  }
);

export default SEVDESK_API;
