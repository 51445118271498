/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { logout } from '@/services/auth';

export default {
  namespaced: true,

  state: {
    accessToken: null,
    expires: null
  },

  getters: {
    auth(state) {
      return state;
    },

    accessToken: () => {
      return localStorage.getItem('auth_token');
    }
  },

  mutations: {
    async CLEAR(state) {
      state.accessToken = null;
      state.expires = null;

      try {
        await Vue.prototype.$vlf.removeItem('directus_refresh_token');
        // await Vue.prototype.$vlf.removeItem('access_token');
        // console.log('save access token to store', payload);
      } catch (error) {
        // console.log('error save access token to localStorage', error);
      }
    },

    async SET_AUTH(state, payload) {
      state.accessToken = payload.access_token;
      state.expires = payload.expires;

      if (payload.directus_refresh_token) {
        try {
          await Vue.prototype.$vlf.setItem('directus_refresh_token', payload.directus_refresh_token);
          // await Vue.prototype.$vlf.setItem('access_token', payload.access_token);
          // console.log('save access token to store', payload);
        } catch (error) {
          // console.log('error save access token to localStorage', error);
        }
      }
    }
  },

  actions: {
    async LOGOUT() {
      return logout()
        .then(response => {
          return response;
        })
        .catch(err => {
          return err;
        });
    }
  }
};
