import * as TrackingService from '@/services/timetracker';
import { findIndex, get } from 'lodash';
import moment from 'moment';

function getDiffHours(start, end) {
  let startDate = start;
  let endDate = end;

  startDate = moment(start);
  endDate = moment(end);

  const duration = moment.duration(endDate.diff(startDate));
  const hours = duration.asHours();

  return Number.parseFloat(hours);
}

export default {
  namespaced: true,

  state: {
    all: [{ start: null, end: null }],
    filter: {
      company: null,
      project: null,
      tag: null,
      start: null,
      end: null,
      billed: 0
    },
    form: {
      project: null,
      tag: null,
      start: null,
      end: null,
      startDay: null,
      description: null
    },
    time: {},
    results: {
      meta: {
        total_hours: 0,
        total_income: 0
      },
      data: []
    },
    targetFigures: {
      day: 8,
      week: 40
    }
  },

  getters: {
    all(state) {
      return state.all;
    },

    monthWorkHours(state, getters) {
      let result = 0;

      getters.monthWorkTracking.map(item => {
        const hours = getDiffHours(item.start, item.end);
        result += hours;
      });

      return result;
    },

    monthWorkTracking(state) {
      const month = new Date().getMonth();

      const start = moment()
        .month(month)
        .date(1)
        .hours(0)
        .minutes(0);
      const end = moment()
        .hours(23)
        .minutes(59);

      const items = state.all.filter(item => {
        return moment(item.start).isBetween(start, end);
      });

      return items;
    },

    monthTotalRevenue(state, getters) {
      let result = 0;
      getters.monthWorkTracking.forEach(item => {
        const income = get(item, 'income');
        if (income && income > 0) {
          result += income;
        }
      });

      return result;
    },

    todayWorkTracking(state) {
      const today = moment()
        .hours(0)
        .minutes(0);

      const items = state.all.filter(item => {
        return moment(item.start).isSameOrAfter(today);
      });

      return items;
    },

    todayWorkHours(state, getters) {
      let result = 0;

      if (getters.todayWorkTracking.length) {
        getters.todayWorkTracking.map(item => {
          const hours = getDiffHours(item.start, item.end);
          result += hours;
        });
      }

      return result;
    },

    todayWorkPercentage(state, getters) {
      if (getters.todayWorkHours === 0) {
        return 0;
      }

      return Math.round((getters.todayWorkHours * 100) / state.targetFigures.day);
    },

    todayTotalRevenue(state, getters) {
      let result = 0;
      getters.todayWorkTracking.forEach(item => {
        const income = get(item, 'income');
        if (income && income > 0) {
          result += income;
        }
      });

      return result;
    },

    weekWorkHours(state, getters) {
      let result = 0;

      getters.weekWorkTracking.map(item => {
        const hours = getDiffHours(item.start, item.end);
        result += hours;
      });

      return result;
    },

    weekWorkPercentage(state, getters) {
      if (getters.weekWorkHours === 0) {
        return 0;
      }

      return Math.round((getters.weekWorkHours * 100) / state.targetFigures.week);
    },

    weekWorkTracking(state) {
      const start = moment()
        .day(1)
        .hours(0)
        .minutes(0);
      const end = moment()
        .day(7)
        .hours(23)
        .minutes(59);

      const items = state.all.filter(item => {
        return moment(item.start).isBetween(start, end);
      });

      return items;
    },

    weekTotalRevenue(state, getters) {
      let result = 0;
      getters.weekWorkTracking.forEach(item => {
        const income = get(item, 'income');
        if (income && income > 0) {
          result += income;
        }
      });

      return result;
    },

    yearWorkHours(state) {
      let result = 0;

      state.all.map(item => {
        const hours = getDiffHours(item.start, item.end);
        result += hours;
      });

      return result;
    },

    yearWorkTracking(state) {
      const year = new Date().getFullYear();

      const start = moment(`${year}-01-01 00:00:00`);
      const end = moment()
        .hours(23)
        .minutes(59);

      const items = state.all.filter(item => {
        return moment(item.start).isBetween(start, end);
      });

      return items;
    },

    yearTotalRevenue(state, getters) {
      let result = 0;
      getters.yearWorkTracking.forEach(item => {
        const income = get(item, 'income');
        if (income && income > 0) {
          result += income;
        }
      });

      return result;
    }
  },

  mutations: {
    CLEAR_FILTERS(state) {
      state.filter.company = null;
      state.filter.project = null;
      state.filter.tag = null;
      state.filter.start = null;
      state.filter.end = null;
      state.filter.billed = null;
    },

    SET_FILTERS(state, payload) {
      state.filter.company = payload.company;
      state.filter.project = payload.project;
      state.filter.tag = payload.tag;
      state.filter.start = payload.start;
      state.filter.end = payload.end;
      state.filter.billed = payload.billed;
    },

    SET_FORMULAR(state, payload) {
      state.form.project = payload.project;
      state.form.tag = payload.tag;
      state.form.start = payload.start;
      state.form.end = payload.end;
      state.form.startDay = payload.startDay;
      state.form.description = payload.description;
    },

    SET_TIMETRACKER(state, payload) {
      const index = findIndex(state.all, item => {
        return item.id === payload.id;
      });

      if (index !== -1) {
        state.all[index] = payload;
      } else {
        state.all.push(payload);
      }
    },

    SET_TIMETRACKER_RESULT(state, payload) {
      const { data, meta } = payload;
      if (meta) {
        state.results.meta = meta;
      }
      if (data) {
        state.results.data = data;
      }
    }
  },

  actions: {
    ADD_TIMETRACKER({ commit }, payload) {
      return TrackingService.addTimetracker(payload)
        .then(response => {
          const { data } = response;
          if (data) {
            commit('SET_TIMETRACKER', response.data);
          }
          return response;
        })
        .catch(err => {
          return err;
        });
    },

    GET_TIMETRACKER({ state }, payload) {
      return TrackingService.getTimetracker(payload.filter)
        .then(response => {
          const { data } = response;
          if (data) {
            state.all = data.slice();
          }

          return response;
        })
        .catch(err => {
          return err;
        });
    },

    GET_TIMETRACKING_RESULTS({ commit, state }) {
      const filter = {
        start: {
          _gte: state.filter.start
        },
        end: {
          _lte: state.filter.end
        },
        billed: {
          _eq: state.filter.billed
        }
      };

      if (state.filter.project) {
        filter.project = {
          _eq: state.filter.project
        };
      }

      if (state.filter.company) {
        filter.project = filter.project || {};
        filter.project.company = {
          _eq: state.filter.company
        };
      }

      if (state.filter.tag) {
        filter.tag = {
          _eq: state.filter.tag
        };
      }

      return TrackingService.getTimetracker(filter)
        .then(response => {
          const { data, meta } = response;
          if (data) {
            commit('SET_TIMETRACKER_RESULT', { data, meta });
          }
          return response;
        })
        .catch(err => {
          return err;
        });
    },

    UPDATE_TIMETRACKER({ commit }, payload) {
      return TrackingService.updateTimetracker(payload)
        .then(response => {
          const { data } = response;
          if (data) {
            commit('SET_TIMETRACKER', data);
          }
          return response;
        })
        .catch(err => {
          return err;
        });
    }
  }
};
