import Vue from 'vue';

// APP
import App from '@/App.vue';

// ROUTER
import router from '@/router/index';

// STORE
import store from '@/store/index';

// PLUGINS
import '@/plugins/element';

// STYLES
import '@/assets/scss/styles.scss';

// STORAGE
// import VueLocalStorage from 'vue-localstorage';
import Vlf from 'vlf';
import localforage from 'localforage';

// import VueProgressBar from 'vue-progressbar';

// const progressbarOptions = {
//     color: '#3498db',
//     failedColor: '#D80F16',
//     thickness: '5px',
//     transition: {
//         speed: '0.2s',
//         opacity: '0.6s',
//         termination: 300
//     }
// };

/**
 * Use Plugins
 */
// Vue.use(VueProgressBar, progressbarOptions);
// Vue.use(VueLocalStorage);
Vue.use(Vlf, localforage);

Vue.prototype.$store = store;

// CONFIG
Vue.config.productionTip = false;

new Vue({
  router,

  store,

  // localStorage,

  render: h => h(App)
}).$mount('#app');
