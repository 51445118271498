<template>
  <div class="app app__layout">
    <el-container>
      <!-- Header -->
      <el-header>
        <AppHeader />
      </el-header>

      <!-- Main container -->
      <el-container class="el-container--main">
        <AppOffCanvas :component="offcanvas.component" :visible="offcanvas.visible">
          <template v-slot:headline>{{ offcanvas.headline }}</template>
        </AppOffCanvas>
        <el-main>
          <!-- Main content goes here -->
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import axios from 'axios';
import client from '@/config/directus.api';
import mixins from '@/shared/mixins/index';

import AppHeader from '@/components/App/AppHeader';
import AppOffCanvas from '@/components/App/AppOffCanvas';

export default {
  name: 'AppLayout',

  mixins: [mixins],

  components: {
    AppHeader,
    AppOffCanvas
  },

  computed: {
    ...mapGetters({
      auth: 'auth/auth'
    }),

    offcanvas() {
      return this.$store.state.offcanvas;
    },

    user() {
      return this.$store.state.user.current;
    },

    token() {
      return client.transport.axios.defaults.headers?.Authorization?.split(' ')[1] || null;
    },

    version() {
      return process.env.VUE_APP_VERSION;
    }
  },

  data() {
    return {
      refreshTokenInterval: null
    };
  },

  created() {
    this.init();
  },

  methods: {
    /**
     * Directus hat einen Bug beim Refreshen des Token, weshalb dies manuell gemacht werden muss. Hierzu ist es erforderlich dass, der refresh_token,
     * welcher im localForage gespeichert ist, mitgeschickt und der neue Refresh Token auch wieder im localForage gespeichert wird.
     */
    async handleToken() {
      // Clear interval
      if (this.refreshTokenInterval) {
        clearInterval(this.refreshTokenInterval);
      }

      try {
        // Hole refresh_token as dem Storage
        const directusRefreshToken = await this.$vlf.getItem('directus_refresh_token');

        // Aktualisiere das access_token mit dem directus_refresh_token
        const response = await client.axios.post('/auth/refresh', {
          refresh_token: directusRefreshToken
        });

        const newAccessToken = get(response, 'data.data.access_token');
        const newRefreshToken = get(response, 'data.data.refresh_token');
        const expires = get(response, 'data.data.expires');

        if (!newAccessToken || !newRefreshToken) {
          return Promise.reject();
        }

        // Refresh token before it expires
        if (expires) {
          this.refreshTokenInterval = setInterval(() => {
            this.handleToken();
          }, expires - 10000);
        }

        // Alle Anfragen mit dem neuen access_token ausführen
        client.auth.token = newAccessToken;

        // Neues access_token und directus_refresh_token im Store speichern
        this.$store.commit('auth/SET_AUTH', {
          access_token: newAccessToken,
          directus_refresh_token: newRefreshToken,
          expires
        });
      } catch (error) {
        throw new Error('Cannot get directus refresh token');
      }

      return Promise.resolve();
    },

    async init() {
      // On page refresh handle token and if the token is due to expire get a new one
      // if (!this.token) {
      //   await this.handleToken();
      // } else if (this.auth.expires) {
      //   this.refreshTokenInterval = setInterval(() => {
      //     this.handleToken();
      //   }, this.auth.expires - 10000);
      // }
      this.requestInitialData();
    },

    requestInitialData() {
      this.$store.commit('SET_TOGGLE_LOADING');

      this.$store.dispatch('user/GET_CURRENT_USER').finally(() => {
        this.$store.commit('HIDE_LOADING');
      });

      const REQUESTS = [];
      REQUESTS.push(this.requestProjects());
      REQUESTS.push(this.requestTags());
      REQUESTS.push(this.requestTimetracker());
      REQUESTS.push(this.requestCompanies());
      REQUESTS.push(this.requestTargetFigures());

      axios
        .all(REQUESTS)
        .then(
          axios.spread((...results) => {
            // this.isLoadingNews = false;
          })
        )
        .catch(() => {
          // this.isLoadingNews = false;
        });
    },

    requestCompanies() {
      return this.$store
        .dispatch('company/GET_COMPANIES')
        .then(response => {
          return response;
        })
        .catch(err => {
          return err;
        });
    },

    requestProjects() {
      return this.$store
        .dispatch('project/GET_PROJECTS')
        .then(response => {
          return response;
        })
        .catch(err => {
          return err;
        });
    },

    requestTags() {
      return this.$store
        .dispatch('tag/GET_TAGS')
        .then(response => {
          return response;
        })
        .catch(err => {
          return err;
        });
    },

    async requestTargetFigures() {
      const year = new Date().getFullYear();

      return await this.$store.dispatch('targetFigures/GET_TARGET_FIGURES', {
        filter: {
          year: {
            _eq: `${year}`
          }
        }
      });
    },

    requestTimetracker() {
      const year = new Date().getFullYear();

      return this.$store
        .dispatch('tracking/GET_TIMETRACKER', {
          filter: {
            start: {
              _gte: `${year}-01-01 00:00:00`
            }
          }
        })
        .then(response => {
          return response;
        })
        .catch(err => {
          return err;
        });
    }
  }
};
</script>

<style lang="scss">
body {
  overflow: hidden;
}

.el-container {
  position: relative;

  &--main {
    height: calc(100vh - 80px);
  }
}

.el-header {
  background-color: #ffffff;
  border-bottom: 1px solid $c-medium-gray;
  height: auto !important;
}

.el-dialog--open {
  .el-main {
    overflow: hidden;
  }
}

.el-main {
  background-color: $c-page-bg;
  padding: 0rem !important;
  position: relative;
}
</style>
