<template>
  <div class="view">
    <div class="view__inner">
      <!-- Logo -->
      <div class="text-center">
        <SVGLogo class="view__logo" />
      </div>
      <!-- Login Form -->
      <FormLogin class="p-t-2 p-b-3" />
    </div>
  </div>
</template>

<script>
import FormLogin from '@/components/Form/FormLogin.vue';
import SVGLogo from '@/assets/img/logo.svg';

export default {
  name: 'Login',

  components: {
    FormLogin,
    SVGLogo
  }
};
</script>

<style lang="scss">
.view {
  &__inner {
    background-color: #ffffff;
    border-radius: $border-radius;
    box-shadow: 0 0 15px 0 transparentize($c-gray, 0.8);
    padding: 2rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 30rem;
    min-width: 30rem;
    width: 100%;
  }

  &__logo {
    text {
      fill: $c-black;
    }
  }
}
</style>
