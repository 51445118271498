// import HalfHalfLayout from '@/layouts/HalfHalfLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import Logout from '@/views/Logout.vue';

export default {
  path: '/logout',
  component: DefaultLayout,
  children: [
    {
      path: '',
      name: 'Logout',
      component: Logout,
      meta: { auth: false }
    }
  ],
  meta: { auth: false }
};
