import AppLayout from '@/layouts/AppLayout.vue';

export default {
  path: '/sales',
  component: AppLayout,
  children: [
    {
      path: '',
      name: 'sales',
      component: () => import(/* webpackChunkName: "sales" */ '@/views/Sales/SalesIndex.vue'),
      meta: { auth: true }
    }
  ],
  meta: { auth: true }
};
