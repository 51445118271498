import client from '@/config/directus.api';

const COLLECTION = 'target_figures';

export async function getTargetFigures(filter = {}) {
  return await client.items(COLLECTION).readByQuery({
    filter,
    fields: ['*', 'type.*']
  });
}
