<template>
  <div class="app__layout-default">
    <div class="text-center"></div>

    <div class="app__layout-default__content-wrapper">
      <!-- Main content goes here -->
      <router-view class="flex a-center valign-center flex-column"></router-view>
    </div>

    <div class="text-center m-b-2" style></div>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout'
};
</script>

<style lang="scss">
.app__layout-default {
  //background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%);
  //background-color: $c-extra-light-gray;
  background-color: rgb(40, 67, 135);
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  justify-content: space-between;

  @media screen and (min-width: $screen-sm) {
  }

  &__content-wrapper {
    width: 100%;
  }
}
</style>
