<template>
  <div id="app" v-loading="loading" :element-loading-text="`Laden...Bitte warten.`">
    <router-view></router-view>

    <!-- <vue-progress-bar></vue-progress-bar> -->

    <!-- Shared components -->
    <Dialog></Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog.vue';

export default {
  name: 'app',

  components: {
    Dialog
  },

  computed: {
    loading() {
      return this.$store.state.app.loading;
    },

    translations() {
      return this.$store.state.translations;
    },

    user() {
      return this.$store.state.user.current;
    }
  },

  mounted() {},

  methods: {}
};
</script>
