// Layouts
import DefaultLayout from '@/layouts/DefaultLayout.vue';

// Views
import NotFound from '@/views/404.vue';

// Routes
import DashboardRoutes from '@/router/routes/dashboard.route';
import LoginRoutes from '@/router/routes/login.route';
import LogoutRoutes from '@/router/routes/logout.route';
import ReportRoutes from '@/router/routes/report.route';
import SalesRoutes from '@/router/routes/sales.route';
import TimesRoutes from '@/router/routes/times.route';

const routes = [
  DashboardRoutes,

  LoginRoutes,

  LogoutRoutes,

  ReportRoutes,

  SalesRoutes,

  TimesRoutes,

  { path: '/', redirect: '/login' },

  {
    path: '*',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: NotFound,
        meta: {
          requiresAuth: false
        }
      }
    ]
  }
];

export default routes;
