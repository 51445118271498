import client from '@/config/directus.api';
const COLLECTION = 'tasktags';

export function getTags() {
  return client
    .items(COLLECTION)
    .readByQuery({
      sort: 'name'
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
