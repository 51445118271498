// import HalfHalfLayout from '@/layouts/HalfHalfLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import Login from '@/views/Login.vue';

export default {
  path: '/login',
  component: DefaultLayout,
  children: [
    {
      path: '',
      name: 'Login',
      component: Login,
      meta: { auth: false }
    }
  ],
  meta: { auth: false }
};
