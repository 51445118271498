import client from '@/config/directus.api';
import store from '@/store/index';

/**
 * Clear user data
 *
 */
export function clearUserData() {
  store.commit('auth/CLEAR');
}

/**
 * Logout user
 *
 * @returns {promise}
 */
export function logout() {
  return client.auth.logout();
}

export function refresh() {
  return client.auth.refresh();
}
