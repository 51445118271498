<template>
  <aside class="offcanvas" :class="['offcanvas--' + alignment, { 'is-visible': visible }]">
    <header class="offcanvas__header">
      <h3 class="offcanvas__headline">
        <slot name="headline">Überschrift</slot>
      </h3>

      <!-- Toogle Button -->
      <el-button type="text" class="btn-offcanvas" @click.prevent="close">
        <i class="material-icons">close</i>
      </el-button>
    </header>

    <main class="offcanvas__body">
      <!-- Dynamic components goes here -->
      <component v-if="component && visible" :is="loadComponent" :data="data"></component>
      <p class="text-center font-brand" v-else>No data available</p>
    </main>
  </aside>
</template>

<script>
export default {
  name: 'AppOffCanvas',

  props: {
    alignment: {
      type: String,
      required: false,
      default: 'right'
    },

    component: {
      type: String,
      required: false,
      default: ''
    },

    data: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },

    visible: {
      type: Boolean,
      required: true
    },

    width: {
      type: String,
      required: false,
      default: '30%'
    }
  },

  computed: {
    loader() {
      if (!this.component) {
        return this.fallbackComponent;
      }
      return () => import(`@/components/${this.component}`);
    }
  },

  data() {
    return {
      loadComponent: null,
      fallbackComponent: () => import('@/components/Tab/TabFormTimetrackingTravelTracking.vue')
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.loader()
        .then(() => {
          this.loadComponent = () => this.loader();
        })
        .catch(() => {
          this.loadComponent = this.fallbackComponent; //--> default component to load
        });
    },

    close() {
      this.$store.commit('offcanvas/CLOSE');
    }
  }
};
</script>

<style lang="scss">
.offcanvas {
  box-shadow: 0 0 18px 0 transparentize($c-black, 0.6);
  transition: all 0.2s ease-in-out;
  min-height: 100%;
  height: auto;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  width: 100%;
  overflow: auto;
  z-index: 99;

  @media screen and (min-width: $screen-sm) {
    width: 70%;
  }

  @media screen and (min-width: $screen-md) {
    min-width: 30rem;
    width: 30%;
  }

  &--left {
    left: 0;
    transform: translate3d(-105%, 0, 0);
  }

  &--right {
    right: 0;
    transform: translate3d(105%, 0, 0);
  }

  &--brand {
    background-color: $c-brand;
  }

  &.is-visible {
    transform: translate3d(0, 0, 0);
  }

  &__separator {
    margin: 2rem 0;
    height: 1px;
    border: 0;
  }

  .btn-offcanvas {
    position: absolute;
    right: 1rem;
    top: 0;
  }

  &__body {
    max-height: calc(100vh - 5rem);
    overflow-y: auto;
    padding: 0 1rem;
  }

  &__headline {
    background-color: $c-extra-light-gray;
    line-height: 1.5rem;
    margin: 0;
    padding: 1.2rem 1rem;
  }
}
</style>
